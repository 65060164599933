import React, { useContext } from "react";
import DesContext from "../../../../../contexts/singleFlow/context";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexFileDropzone from "../../../../Lex/LexFileDropzone";
import LexTypography from "../../../../Lex/LexTypography";
import { Box } from "@mui/material";

const Files = ({ stepId }) => {
    const desCtx = useContext(DesContext);
    const desManager = desCtx.manager;
    const files = desManager.staticFiles;
    return (
        <SidebarInternalWrapper>
            <Box
                style={{
                    paddingBottom: "30px"
                }}>
                <LexTypography
                    variant="h5"
                    sx={{ paddingBottom: 1 }}>
                    File Management
                </LexTypography>
                <LexTypography
                    sx={{paddingTop:2}}
                    variant="body1">
                    Lex Automata allows you to use your files in your automata, so you may query, search, and summarize them at will.
                </LexTypography>
                <LexTypography
                    sx={{paddingTop:2}}
                    variant="body1">
                    The <i>static files</i> below are available for any workspace associated with this automation. You may retrieve the relevant files via a Search step.
                </LexTypography>
            </Box>
            <LexFileDropzone
                files={files}
                uploadFuncton={desManager.uploadStaticFiles}
                dependencies={[desManager.staticFiles]}
                deleteFunction={(index) => () => desManager.deleteStaticFile(index, stepId)}
            />
        </SidebarInternalWrapper>
    )
}

export default Files;