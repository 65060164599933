
import LexTypography from '../../../Lex/LexTypography';
import { InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { TextField } from '@mui/material';
import LexHighlightTextField from '../../../Lex/LexHighlightTextField';
import useIsSmallContainer from '../hooks/useIsSmallContainer';
import LexHeaderDescription from '../../../Lex/LexHeaderDescription';
import LexTextField from '../../../Lex/LexTextField';
import LexSimpleSelect from '../../../Lex/LexSimpleSelect';

const outerStyle = {
    borderRadius: "8px",
    paddingBottom: "15px",
}

const sampleInputField = {
    multiline: true,
    rows: 6,
    inputLabel: "Sample Input",
    value: "",
    onChange: (e) => { },
    helperText: ""
}

const Collector = ({ titleText, stepId, descriptionText, inputFields, checkboxFields = [] }) => {
    const { isSmallContainer, containerRef } = useIsSmallContainer();
    return <div ref={containerRef} style={outerStyle}>
        <LexHeaderDescription header={titleText} description={descriptionText} />
        <div style={{
            width: "100%",
            display: 'flex',
            flexDirection: isSmallContainer ? 'column' : 'row'

        }}>
            <div style={{
                width: isSmallContainer ? "100%" : "70%",
                marginTop: isSmallContainer ? '30px' : '0px',
                padding: isSmallContainer ? '10px' : '0px',
                borderRadius: isSmallContainer ? '10px' : '0px',
                border: isSmallContainer ? 'solid 1px rgba(0,0,0,.2)' : '0px'
            }}>
                {inputFields.map((inputField, idx) => {
                    if (inputField.useHighlights) {
                        return <div
                            key={'collector_' + stepId + idx}
                            style={{ width: "100%" }}><LexHighlightTextField
                                id="filled-basic"
                                style={{ width: isSmallContainer ? "90%" : "90%" }}
                                variabes={{ 'file': 'file' }}
                                multiline={inputField.multiline}
                                rows={inputField.rows}
                                keysObject={inputField.keyObject}
                                possibleKeyObject={inputField.possibleKeyObject}
                                label={inputField.inputLabel}
                                value={inputField.value}
                                onChange={inputField.onChange}
                                variant="filled"
                                InputProps={
                                    (inputField.targetName === "varName"
                                        || inputField.targetName === "fileVarName")
                                        ? { 
                                            startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment> }
                                        : {}}
                                helperText={inputField.helperText}
                            /> </div>
                    } else if (inputField.select) {
                        return <LexSimpleSelect options={inputField.options}
                            displayVal={inputField.displayVal}
                            variant="filled"
                            handleChange={inputField.onChange}
                            fullWidth={false}
                            style={{ width: isSmallContainer ? "90%" : "90%" }}
                            value={inputField.value}
                            title={inputField.inputLabel} 
                            helperText={inputField.helperText}
                            />
                    } else {
                        return < div
                            style={{ width: "100%" }}
                            key={'collector_' + stepId + idx}
                        > <LexTextField
                                style={{ width: isSmallContainer ? "90%" : "90%" }}
                                id="filled-basic"
                                variabes={{ 'file': 'file' }}
                                multiline={inputField.multiline}
                                rows={inputField.rows}
                                keysObject={{ 'file': 'test' }}
                                label={inputField.inputLabel}
                                value={inputField.value}
                                filter={inputField?.filter ? inputField.filter : (text)=>text}
                                onChange={inputField.onChange}
                                variant="filled"
                                inputProps={{maxlength: inputField?.maxLength}}
                                InputProps={
                                    (inputField.targetName === "varName"
                                        || inputField.targetName === "fileVarName")
                                        ? { 
                                            startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment> }
                                        : {}}
                                helperText={inputField.helperText}
                            /> </div>
                    }
                })
                }
            </div>
            {checkboxFields.length > 0 ? <div style={{
                marginTop: isSmallContainer ? '30px' : '0px',
                padding: isSmallContainer ? '10px' : '0px',
                borderRadius: isSmallContainer ? '10px' : '0px',
                border: isSmallContainer ? 'solid 1px rgba(0,0,0,.2)' : '0px'

            }}>
                <FormGroup>
                    {checkboxFields.map(checkboxField => {
                        return <FormControlLabel
                            key={stepId + checkboxField.label + "a1"}
                            control={
                                <Checkbox checked={checkboxField.value} onChange={checkboxField.onChange} name={checkboxField.label} />
                            }
                            label={checkboxField.label}
                        />
                    })
                    }
                </FormGroup>
            </div> : null}
        </div>

    </div >
};

export default Collector;