import Collector from '../../Common/Collector';
import { quickCheck, quickInput } from '../../Common/utilities';

const APIStepCollector = ({ 
    configureStep, 
    stepData, 
    errorDisplay,
    stepId,
    testState,
    totalState,
    totalPrevState,
    prevState,
    macrosObject, 
    availableVariables,
    ...other}) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Target API",
                target:"targetAPI",
                helperText:"This is the target API",
                select:true,
                options:['Edgar'],
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Output Variable",
                target:"varName",
                helperText:"This is the variable the output will be stored in.",
                maxLength:40,
                filter: (text)=>text.replace(/[^a-zA-Z0-9]/g, ''),
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"API Query",
                target:"prompt",
                helperText:"This is the query that will be sent to the API.",
                multiline:true,
                rows:8,
                useHighlights:true,
                keyObject:{ ...macrosObject, ...totalPrevState },
                possibleKeyObject:availableVariables
            }),
        ]}
    />
};

export default APIStepCollector;