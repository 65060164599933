import React, { useState, useContext} from 'react';
import DesContext from './context.js';
import WorkflowContext from '../workflow/context.js';
import APIContext from '../api/context.js';
import { useMockDialog } from '../api/hooks.js';
import { useCitations } from './hooks/useCitations.js';
import { usePreviewMode } from './hooks/usePreviewMode.js';
import { useMacros } from './hooks/useMacros.js';
import { useStaticFiles } from './hooks/useStaticFiles.js';
import { useOtherFiles } from './hooks/useOtherFiles.js';
import { useWorkflowTriggers } from './hooks/useWorkflowTriggers.js';
import { useLLMControls } from './hooks/useLLMControls.js';
import { useStepModifiers } from './hooks/useStepModifiers.js';
import { usePlayAndFF } from './hooks/usePlayAndFF.js';
import { useImportExport } from './hooks/useImportExport.js';
import { useWorkspaces } from './hooks/useWorkspaces.js';
import { useStepMutations } from './hooks/useStepMutations.js';
import { useStateUpdateController } from './hooks/useStateUpdateController.js';

const DesContextProvider = ({ children }) => {
    const { api, apiWithLoading } = useContext(APIContext)

    // Workflow Context
    const { activeWorkflow, manager } = useContext(WorkflowContext);

    // Workflow Triggers
    const {saveWorkflowVer,loadRefreshWorkflow, workflowVersion,...triggers} = useWorkflowTriggers()
    
    // LLM Controls
    const { LLMControlsOps, LLMControlsOther } = useLLMControls(triggers.saveWorkflow)
    const { model, setModel } = LLMControlsOther

    // Static Files
    const { staticFileOps, staticFilesOther } = useStaticFiles(
        activeWorkflow,
        api,
        apiWithLoading,
        triggers.refresh)
    const staticFiles = staticFileOps.staticFiles;

    // Macros
    const macrosOps = useMacros(api);
    const macrosObject = macrosOps.macrosObject;

    // Main State
    const [steps, setSteps] = useState([]);
    const [stepData, setStepData] = useState({});
    const [isRunning, setIsRunning] = useState(false)
    const [testProfiles, setTestProfiles] = useState({});
    const [activeTestProfile, setActiveTestProfile] = useState([]);
    const [activeTestProfileMeta, setActiveTestProfileMeta] = useState({});
    const [activeTestProfileId, setActiveTestProfileId] = useState("Default Profile");
    console.log(activeTestProfile)
    // Workspaces
    const { workspaceOps, workspaceOther } = useWorkspaces(
        api,
        activeWorkflow,
        activeTestProfile,
        activeTestProfileId,
        triggers.refresh)
    const { activeExecutionId } = workspaceOther

    // Other Files
    const otherFilesOps = useOtherFiles(
        activeWorkflow, 
        api, 
        apiWithLoading,
        activeExecutionId, 
        triggers.saveWorkflow)

    // Load Workflow
    const loadWorkflowGeneral = (flow, flowMetaData) => {
        manager.setActiveWorkflowName(flow.name)
        setSteps(flowMetaData?.steps ? flowMetaData.steps : [])
        setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
        setTestProfiles(flowMetaData?.testProfiles ? flowMetaData.testProfiles : {})
        setActiveTestProfile(flowMetaData?.activeTestProfile ? flowMetaData.activeTestProfile : [])
        setActiveTestProfileMeta(flowMetaData?.activeTestProfileMeta ? flowMetaData.activeTestProfileMeta : {})
        setActiveTestProfileId(flowMetaData?.activeTestProfileUUID ? flowMetaData.activeTestProfileUUID : null)
        triggers.setWorkflowVersion(flowMetaData?.workflowVersion ? flowMetaData.workflowVersion : 1)
        setModel(flowMetaData?.model ? flowMetaData.model : 'GPT-4o')
    }

    // State Update Controller
    const {actionPackager,flowStateController,updateTestProfile} = useStateUpdateController(
        api,
        manager,
        activeWorkflow,
        activeExecutionId,
        testProfiles, 
        activeTestProfile,
        activeTestProfileId,
        activeTestProfileMeta, 
        steps,
        stepData,
        staticFiles,
        model,
        macrosObject,
        workflowVersion, 
        saveWorkflowVer,
        loadRefreshWorkflow,
        loadWorkflowGeneral,
        staticFilesOther.loadStaticFiles,
        setSteps,
        setStepData,
        setActiveTestProfile,
        setIsRunning,
        triggers,
    ) 

    // Step Modifiers
    const stepModifierOps = useStepModifiers(
        useMockDialog, 
        flowStateController, 
        triggers.saveWorkflow)

    // Play and Fast Forward
    const playAndFFOps = usePlayAndFF(
        api, 
        setIsRunning, 
        updateTestProfile, 
        actionPackager,
        triggers.saveWorkflow)

    // Step Mutations
    const stepMutations = useStepMutations(
        activeTestProfile,
        activeTestProfileId,
        macrosObject,
        setStepData,
        setActiveTestProfile,
        steps,
        stepData)
    
    // Import/Export
    const importExportOps = useImportExport(
        api,
        activeWorkflow,
        loadWorkflowGeneral, 
        triggers.saveWorkflow)
    
    // Preview Mode
    const previewOps = usePreviewMode(
        activeWorkflow,
        steps,
        actionPackager,
        apiWithLoading,
        updateTestProfile,
        setIsRunning)

    // Citations
    const citationOps = useCitations(
        apiWithLoading,
        activeWorkflow,
        activeExecutionId);

    return (
        <DesContext.Provider value={{
            manager: {
                isRunning,
                steps, stepData,
                ...stepMutations,
                ...stepModifierOps,
                ...playAndFFOps,
                ...workspaceOps,
                ...importExportOps,
                ...LLMControlsOps,
                ...otherFilesOps,
                ...staticFileOps,
                ...macrosOps,
                ...previewOps,
                ...citationOps,
            }
        }}>

            {children}
        </DesContext.Provider>
    );
}

export default DesContextProvider;