import { useState, useEffect } from "react";
import { downloadBlobURL } from "../../../utilities/downloadBlobURL";
import { embedHiddenStatePre, retrieveHiddenStatePre, deleteHiddenStatePre } from "../../../components/Automata/Common/hooks/useHiddenState";


export const useOtherFiles = (activeWorkflow, api, apiWithLoading, activeExecutionId, saveWorkflow) => {
    const uploadFiles = ({ configureStep, stepData, testState, stepIndex, stepId, mode }) => async (acceptedFiles) => {
        let newFiles = []
        for (var i = 0; i < acceptedFiles.length; i++) {
            let acceptedFile = acceptedFiles[i];
            let formData = new FormData();
            formData.append('files', acceptedFile);
            formData.append('flowId', activeWorkflow);
            formData.append('mode', mode);
            formData.append('stepId', stepId);
            formData.append('executionId', activeExecutionId);
            const response = await apiWithLoading('/ptntst/uploadFile', formData, {headers: {'Content-Type': 'multipart/form-data'}})
            if(response.status === 200){
                if (mode == "template") {
                    let fileId = 'template'
                    configureStep.set({
                        [fileId]: {
                            blobName: response.data.blobName,
                            origName: response.data.origName,
                            mimeType: response.data.mimeType,
                            flowId: activeWorkflow
                        },
                    })
                } else {
                    newFiles = [...newFiles, {
                        blobName: response.data.blobName,
                        origName: response.data.origName,
                        mimeType: response.data.mimeType,
                        flowId: activeWorkflow,
                        executionId:activeExecutionId,
                        module:"uploadFile"
                    }]
                }
            }
        }

        if (mode !== "template") {
            const embedHiddenState = embedHiddenStatePre(testState, configureStep, stepId, 2)
            const retrieveHiddenState = retrieveHiddenStatePre(testState, configureStep, stepId, 2)
            let oldHiddenFileState = retrieveHiddenState('files', [])
            embedHiddenState('files', [...oldHiddenFileState, ...newFiles])
        }
        setTimeout(() => {
            saveWorkflow()
        },1000)
        
    }
    
    const downloadCompletedTemplate = ({ stepData, stepIndex, stepId }) => () => {
        apiWithLoading('/ptntst/downloadCompletedTemplate',
            {
                flowId: activeWorkflow,
                stepData,
                stepIndex,
                stepId
            },
            {
                responseType: 'blob',
                headers: {}
            }).then(res => {
                // Check if res.data is actually a Blob
                if (!(res.data instanceof Blob)) {
                    console.error('Response data is not a Blob:', res.data);
                    return;
                }
                const href = URL.createObjectURL(res.data);
                downloadBlobURL(href, "completedTemplate.docx")

            }).catch((e) => {
                console.log(e)
            })
    }

    const deleteFile = (mode) => async ({ configureStep, stepData, files, testState, stepIndex, stepId, file_idx }) => {
        const formData = new FormData();
        if (files.length > 0) {
            formData.append('flowId', activeWorkflow);
            formData.append('fileData', JSON.stringify(files[file_idx]));
            apiWithLoading('/ptntst/deleteFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (mode == "template") {
                    let fileId = 'template'
                    configureStep.deleteSetKey(fileId)
                } else {
                    const embedHiddenState = embedHiddenStatePre(testState, configureStep, stepId, 2)
                    const modified_files = files.filter((file, index) => index !== file_idx)
                    embedHiddenState('files', modified_files)
                }
                setTimeout(() => {
                    saveWorkflow()
                }, 100)
            }).catch((e) => {   
                console.log(e)
            });
        }
    };
    return { uploadFiles, deleteFile, downloadCompletedTemplate }

}