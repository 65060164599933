
import React,{useEffect, useState} from 'react';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import APIStepCollector from '../../../components/Automata/API/APIStepCollector';
import APITestDisplay from '../../../components/Automata/API/APITestDisplay';
import { Box } from '@mui/material';
import LexTypography from '../../../components/Lex/LexTypography';


export const apiStep = {
    stepMenuOptions: ['apiSelect','stepMetaData','outputDisplay'],
    initializedStep:'apiSelect',
    execution: (props)=>
        <Box sx={{padding:2,border:1,borderRadius:3,borderColor:'action.disabled',marginTop:3}}>
        <LexTypography  sx={{paddingBottom:2}} variant="h6">API Search Results</LexTypography>
        <APITestDisplay fixHeight={'500px'} {...props} />
        </Box>,
    executionSingleStepText: 'Query API',
    continueCondition: ({stepData,testState}) => testState?.[stepData.varName],
    overview: {
        name: 'apiStep',
        label: 'Pull data from an external API.',
        sublabel: 'Set up your automation to retrieve data from an external API.',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'apiStep',
                labelDisplay: "API",
                display: 'apiSelect',
                targetAPI: 'Edgar',
                initialized: true
            })
        }
    },
    outputDisplay: {
        label: 'Test Output',
        // useExpandableDescription: true,
        enabled: true,
        collectorComponent: props=><APITestDisplay {...props} />

    },
    apiSelect: {
        header: 'API Selection',
        label: 'Step Configuration',
        useExpandableDescription: true,
        description: 'Please select the API you wish to use and enter your query.',
        collectorComponent: (props) => <APIStepCollector {...props} />,
    },
    default: {

    }


};
