import { use } from "react";

export const generateOutputs = (testState, stepId) => {
    const state = testState?.[stepId] || {};
    return Object.keys(state).map(key => {
        let label = key.replace(/__/g, '').replace(/_/g, ' ').replace('response', '');
        label = label.charAt(0).toUpperCase() + label.slice(1);
        return { key, label, value: state[key] };
    });
};

export const quickInput = ({
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
    multiline = null,
    rows = null,
    useHighlights = false,
    filter = null,
    maxLength = null,
    keyObject = {},
    possibleKeyObject = {},
    select = false,
    options = [],
    useDisplay = false,
    displayFn = (val) => { return val },
    displayVal = (val) => { return val },
    customOnChange = (e) => { return e.target.value}

}
) => {
    return {
        targetName: target,
        inputLabel: inputLabel,
        value: stepData?.[target],
        multiline: multiline,
        rows: rows,
        onChange: (e) => {
            if (!useDisplay) {
                configureStep.set({
                    [target]: e.target.value,
                })
            }
            else {
                configureStep.set({
                    [target]: customOnChange(e),
                })
            }
        },
        helperText: helperText,
        useHighlights: useHighlights,
        filter: filter,
        maxLength: maxLength,
        select: select,
        options: options,
        keyObject: keyObject,
        possibleKeyObject: possibleKeyObject,
        useDisplay: useDisplay,
        displayVal:displayVal
    }
}

export const quickTestInput = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
    multiline = null,
    rows = null,
    useHighlights = false,
    keyObject = {},
) => {
    return {
        targetName: target,
        inputLabel: inputLabel,
        value: keyObject?.[target],
        multiline: multiline,
        rows: rows,
        onChange: (e) => {
            configureStep.setTest({
                [target]: e.target.value,
            })
        },
        helperText: helperText,
        useHighlights: useHighlights,
        keyObject: keyObject
    }
}


export const quickCheck = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
) => {
    return {
        targetName: target,
        label: inputLabel,
        value: stepData?.[target],
        onChange: (e) => {
            configureStep.set({
                [target]: e.target.checked,
            })
        },
        helperText: helperText
    }
}

export const subcollectorQuickInput = (
    stepData, configureStep, conf,
    index, fieldData,
    inputLabel, target, helperText
) => ({
    inputLabel: inputLabel,
    targetName: target,
    onChange: conf(configureStep, stepData, index, target),
    value: fieldData?.[target],
    helperText: helperText
})