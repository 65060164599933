import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ContextCollector from '../../../components/Automata/Common/ContextCollector';
import GenerativeRAGCollector from '../../../components/Automata/Search/GenerativeRAGCollector';
import TroubleshootSharpIcon from '@mui/icons-material/TroubleshootSharp';
import LexTypography from '../../../components/Lex/LexTypography';
import GenerativeSearchTestDisplay from '../../../components/Automata/Search/GenerativeSearchTestDisplay';
import { Box } from '@mui/material';

export const ragStep = {
    stepMenuOptions: ['writePrompt', 'stepMetaData', 'outputDisplay'],
    initializedStep: 'writePrompt',
    execution: (props) =>
        <Box sx={{ padding: 2, border: 1, borderRadius:3, borderColor: 'action.disabled', marginTop: 3 }}>
            <LexTypography sx={{ paddingBottom: 2 }} variant="h6">Found Files</LexTypography>
            <GenerativeSearchTestDisplay fixHeight={'500px'}  {...props} />
        </Box>,
    continueCondition: ({ stepData, testState }) => true,
    executionSingleStepText: 'Find Relevant Documents',
    overview: {
        name: 'rag',
        label: 'Retrieval Augmented Generation',
        sublabel: 'Use RAG to semantically search and incorporate your static files into textual generation',
        icon: <TroubleshootSharpIcon />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'ragStep',
                labelDisplay: "Search",
                display: 'primaryOptions',
                initialized: true,
                numResult:'1'
            })
        }
    },
    outputDisplay: {
        label: 'Test Output',
        enabled: true,
        description: "",
        collectorComponent: (props) => <GenerativeSearchTestDisplay {...props} />

    },
    primaryOptions: {
        header: 'RAG Step',
        useExpandableDescription: true,
        description: 'A generative step creates a conservation with the LLM. You may either create a fresh conversation or continue an existing one.  \n\n Under certain cirmcumstances, continuing an existing conversation allows the LLM to utilize the existing context to generate more accurate responses.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: false,
                    display: 'writePrompt'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        useExpandableDescription: true,
        header: 'Continue Conversation?',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector nextStep={'writePrompt'} {...props} />,
    },

    writePrompt: {
        label: 'Step Configuration',
        useExpandableDescription: true,
        header: 'LLM Prompt',
        description: '',
        collectorComponent: (props) => <GenerativeRAGCollector {...props} />,
    },
    default: {

    }


};