import React, { useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DesContext from '../../../../../../contexts/singleFlow/context';
import { PolygonLabel } from '../../../../../Design/Polygons';

const ExportAllavailableVariablesModal = ({ open, onClose, onSubmit }) => {
  const [selectedavailableVariables, setSelectedavailableVariables] = useState([]);
  const [availableVariables, setAvailableVariables] = useState([]);
  const { manager } = useContext(DesContext);

  useEffect(() => {
    if (open) {
      let lastStep = manager.steps[manager.steps.length - 1];
      const stepOperations = manager.createStepOperations({ 
        stepData: manager.stepData,
        stepIndex: manager.steps.length - 1,
        stepId: lastStep
      });
      setAvailableVariables(Object.keys(stepOperations.availableVariablesPre));
    }
  }, [open, manager]);

  const handleToggle = (variable) => {
    setSelectedavailableVariables((prevSelected) =>
      prevSelected.includes(variable)
        ? prevSelected.filter((item) => item !== variable)
        : [...prevSelected, variable]
    );
  };

  const handleSubmit = () => {
    onSubmit(selectedavailableVariables);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle sx={{ p: 0, fontWeight: 'normal', position: 'relative' }}>
        {/* Polygon style header similar to the provided component */}
        <PolygonLabel
          clip="polygon(0 0, calc(100% - 15px) 0, 100%  100%, 0 100%)"
          sx={{ bgcolor: 'flags.right', color: "flags.rightText" }}
          style={{ width: 'fit-content', paddingRight: '2em' }}
        >
          <PolygonLabel
            clip="polygon(0 0, calc(100% - 15px) 0, 100%  100%, 0 100%)"
            height='60px'
            sx={{ bgcolor: 'flags.middle', color: "backgroundSecondary.contrastText" }}
            style={{ width: 'fit-content', paddingRight: '2em' }}
          >
            <PolygonLabel
              className='deleteFormFieldButton'
              clip="polygon(0 0, calc(100% - 15px) 0, 100% 100%, 0 100%)"
              height='60px'
              sx={{ bgcolor: 'flags.left', color: "flags.leftText" }}
              style={{ width: 'fit-content', paddingRight: '2em', paddingLeft: '14px' }}
            >
              Export All Workspaces
            </PolygonLabel>
            <span style={{ paddingLeft: '14px' }} />
          </PolygonLabel>
          <span style={{ paddingLeft: '14px' }} />
        </PolygonLabel>

        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers style={{ maxHeight: '70vh',maxWidth:600, overflowY: 'auto' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          With this dialog, you can easily choose which automation variables should be included in a single CSV export.
          <br/><br/>
          Simply review the list below and select the variables that you want to export by toggling their corresponding checkboxes. 
          When you're satisfied with your selections, click "Download" to generate and download a CSV file containing only the chosen variables.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          {availableVariables.map((variable) => (
            <FormControlLabel
              key={variable}
              control={
                <Checkbox
                  checked={selectedavailableVariables.includes(variable)}
                  onChange={() => handleToggle(variable)}
                />
              }
              label={variable}
            />
          ))}
        </Box>
        <br/><br/>
        <Box fullWidth sx={{position:"absolute", bottom:0,right:20,p:1,display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <Button onClick={onClose} variant="contained" color="error">
            Close
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Download
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ExportAllavailableVariablesModal;
