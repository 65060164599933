import Collector from '../../Common/Collector';
import { quickCheck, quickInput, quickTestInput } from '../../Common/utilities';

const IDIStepCollector = ({ 
    configureStep, 
    stepData, 
    errorDisplay, 
    stepId, 
    testState,
    totalState,
    totalPrevState,
    prevState,
    macrosObject, 
    availableVariables, 
    ...other }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Target Website",
                target:"targetURL",
                helperText:"Please provide the full URL for the target website.",
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Output Variable",
                target:"varName",
                helperText:"This is the variable the output will be stored in.",
                maxLength:40,
                filter: (text)=>text.replace(/[^a-zA-Z0-9]/g, ''),
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Search Query",
                target:"prompt",
                helperText:"This is the query that will be sent to external search engine.",
                multiline:true,
                rows:8,
                useHighlights:true,
                keyObject:{ ...macrosObject, ...totalPrevState },
                possibleKeyObject:availableVariables
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Instructions",
                target:"instructions",
                helperText:"Please provide instructions on how to browse the external website.",
                multiline:true,
                rows:8,
                useHighlights:true,
                keyObject:{ ...macrosObject, ...totalPrevState },
                possibleKeyObject:availableVariables
            })
        ]}
    />
};

export default IDIStepCollector;