import { useEffect} from "react";

export const useStateUpdateController= (
    api,
    manager,
    activeWorkflow,
    activeExecutionId,
    testProfiles, 
    activeTestProfile,
    activeTestProfileId,
    activeTestProfileMeta, 
    steps,
    stepData,
    staticFiles,
    model,
    macrosObject,
    workflowVersion, 
    saveWorkflowVer,
    loadRefreshWorkflow,
    loadWorkflowGeneral,
    loadStaticFiles,
    setSteps,
    setStepData,
    setActiveTestProfile,
    setIsRunning,
    triggers,
) => {
    
    useEffect(() => {
        if (activeWorkflow !== null) {
            console.log(activeTestProfile)
            manager.updateWorkflow(
                steps, 
                stepData, 
                testProfiles, 
                activeTestProfileId, 
                activeTestProfile, 
                activeTestProfileMeta, 
                workflowVersion, 
                model, 
                activeExecutionId)
        }
    }, [saveWorkflowVer])

    useEffect(() => {
        if (activeWorkflow !== null) {
            api('/ptntst/getWorkflow', { flowId: activeWorkflow, executionId: activeExecutionId }).then(res => {
                const flowMetaData = res.data.flow.metadata
                loadWorkflowGeneral(res.data.flow, flowMetaData)
                loadStaticFiles(flowMetaData?.staticFiles)
            })
        }
    }, [activeWorkflow, loadRefreshWorkflow])

    const flowStateController = { steps, setSteps, setStepData, activeTestProfile, setActiveTestProfile }

    const actionPackager = (index) => ({
        flowId: activeWorkflow,
        executionId: activeExecutionId,
        stepId: steps[index],
        steps: steps,
        stepData: stepData,
        index: index,
        stateDataArray: JSON.stringify(activeTestProfile),
        staticFiles: staticFiles,
        model: model,
        macrosObject: macrosObject
    })

    const updateTestProfile = (history) => {
        setIsRunning(false)
        setActiveTestProfile(history)
        triggers.saveWorkflow(100)
    }

    return {actionPackager,flowStateController,updateTestProfile}

}