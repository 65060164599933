import React, { useContext, useState, useEffect } from "react";
import WorkflowContext from "../../../../../contexts/workflow/context";
import DesContext from "../../../../../contexts/singleFlow/context";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexTextField from "../../../../Lex/LexTextField";
import LexSimpleSelect from "../../../../Lex/LexSimpleSelect";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton } from "@mui/material";
import LexTypography from "../../../../Lex/LexTypography";

const Settings = (props) => {
    const { manager, activeWorkflowName } = useContext(WorkflowContext);
    const desCtx = useContext(DesContext);
    const desManager = desCtx.manager;
    const [workspaceName, setWorkspaceName] = useState(desManager.activeExecutionActualName ? desManager.activeExecutionActualName : "");
    const [automationName, setAutomationName] = useState(activeWorkflowName ? activeWorkflowName : "");
    useEffect(() => {
        setWorkspaceName(desManager.activeExecutionActualName)
    }, [desManager.activeExecutionActualName])

    useEffect(() => {
        setAutomationName(activeWorkflowName)
    }, [activeWorkflowName])

    return (
        <SidebarInternalWrapper>
              <Box>
                <LexTypography
                    variant="h5"
                    sx={{ paddingBottom: 1 }}>
                    Automation Settings
                </LexTypography>
            </Box>
            <Box sx={{ border: 1, borderRadius: 2, marginTop: 2, padding: 1, borderColor: 'action.disabled' }}>
                <LexTypography
                    variant="h6"
                    sx={{
                        paddingBottom: 1,
                        paddingTop: 1
                    }}>
                    Automation Wide Settings
                </LexTypography>
                <LexTypography
                    variant="body1"
                    sx={{
                        paddingBottom: 3
                    }}>
                    These settings are applied to all workspaces associated with the automation.
                </LexTypography>
                <LexTextField
                    id="workflowName"
                    label="Workflow Name"
                    variant="filled"
                    name="workflowName"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={automationName || ""}
                    onChange={(e) => { setAutomationName(e.target.value) }} />
                <LexSimpleSelect options={desManager.LLMOptions}
                    handleChange={desManager.handleLLMChange}
                    value={desManager.currentLLM}
                    title="Select LLM" />
                <Box style={{ width: "100%", paddingTop: "25px", display: 'flex', flexDirection: 'column' }}>
                    <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        onClick={() => { manager.renameWorkflow(automationName) }}
                    >
                        Update Automation Name
                    </Button>
                </Box>
            </Box>
            <Box sx={{paddingTop:3}}style={{ display: "flex" }}>
                    <IconButton
                        sx={{ margin: 1, bgcolor: "primary.main", color: "error.contrastText", borderRadius: 2 }}
                        onClick={desManager.handleFileLoad}
                    >
                        <FileOpenIcon />
                    </IconButton>
                    <IconButton
                        sx={{ margin: 1, bgcolor: "primary.main", color: "error.contrastText", borderRadius: 2 }}
                        onClick={desManager.exportWorkflow}
                    >
                        <SaveAltIcon />
                    </IconButton>
                    <IconButton
                        sx={{ margin: 1, bgcolor: "primary.main", color: "error.contrastText", borderRadius: 2 }}
                        onClick={() => { desManager.setPreviewMode(v => !v) }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        sx={{ margin: 1, bgcolor: "error.main", color: "error.contrastText", borderRadius: 2 }}
                        color="error"
                        onClick={manager.deleteWorkflow}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
        </SidebarInternalWrapper>

    )
}

export default Settings;