import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Box, CircularProgress, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const scrollbarThemeing = (theme) => `
html {
  overflow: scroll;
  scrollbar-gutter: stable both-edges;
}

html::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
html::-webkit-scrollbar-track {
  background: transparent;
  border: solid 10px transparent;
  margin-top: 15px;
  margin-left: 15px;
}
html::-webkit-scrollbar-thumb {
  background: rgba(127, 127, 127, 0.5);
  min-height: 40px;
}
`

const LexHTMLDisplay = ({ open, onClose, url, htmlString, documentName, documentAuthor }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const contentRef = useRef(null);

  /*
  useEffect(() => {
    // var iframe = document.getElementById('textfile');
    // document.querySelector('iframe').contentWindow.document.querySelector("body").style.color = "red";
  }, [open]);
  */

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Document Viewer ({documentName})
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={contentRef}
        style={{ height: '80vh', overflowY: 'visible' }}>
        <iframe
          id="textfile"
          style={{
            width: "100%", height: "100%",
            backgroundColor: 'Window', borderRadius: '10px'
          }}
          // sandbox="allow-same-origin allow-forms allow-pointer-lock allow-popups"
          sandbox="" // We have to set this to empty string for sandbox to be rendered in the iframe to disable js.
          srcDoc={"<style>" + scrollbarThemeing(theme) + "</style>" + htmlString} />
      </DialogContent>
    </Dialog>
  );
};

export default LexHTMLDisplay;