import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const GenerativeRAGCollector = ({ configureStep, stepData,stepId,macrosObject,prevState,totalState,totalPrevState,testState, availableVariables, errorDisplay }) => {
    return <Collector
        stepId={stepId}
        descriptionText={"Use the prompt field to specify any static files you'd like to retrieve from the automation. The full text content of the files you select will be automatically collected and stored in the output variable."}
        inputFields={[
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Output Variable",
                target:"fileVarName",
                helperText:"Any files queries will be stored in this variable.",
                maxLength:40,
                filter: (text)=>text.replace(/[^a-zA-Z0-9]/g, ''),
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Number of results to use",
                target:"numResult",
                filter: (text)=>text.replace(/[^0-9]/g, ''),
                helperText:"How many results do you wish to incorporate?",
                select:true,
                options:['1','2','3','4','5'],
            }),
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"LLM Prompt",
                target:"prompt",
                helperText:"This is the prompt/command that will be given to the LLM.",
                multiline:true,
                rows:8,
                useHighlights:true,
                keyObject:{ ...macrosObject, ...totalPrevState },
                possibleKeyObject:availableVariables
            })
        ]}
    />
};

export default GenerativeRAGCollector;