import React, { useState, useEffect, useContext } from 'react';
import WorkflowContext from './context';
import DesContextProvider from '../singleFlow';
import APIContext from '../api/context';
import { useAPIDialog } from '../api/hooks';

const WorkflowContextProvider = ({ children }) => {
    const { api, apiWithDialog, apiForcedRefresh, completeDialogueResponse, dialogResponseActions } = useContext(APIContext)
    const [refresh, setRefresh] = useState(0);
    const [disableNameChange, setDisableNameChange] = useState(false);
    const [activeWorkflow, setActiveWorkflow] = useState(null);
    const [activeWorkflowName, setActiveWorkflowName] = useState(null);
    const [workflows, setWorkflows] = useState([]);

    const [forceSwitchToFlow, setForceSwitchToFlow] = useState(1);

    useEffect(() => { setRefresh(refresh + 1) }, [])
    useEffect(() => {
        api('/ptntst/getWorkflows', {}).then(res => {
            setWorkflows(res.data.flows)
        })
    }, [refresh, apiForcedRefresh]);

    const activateWorkflow = (workflow) => {
        setDisableNameChange(true)
        const handler = setTimeout(() => {
            setDisableNameChange(false)
        }, 1000);
        setActiveWorkflow(workflow)
    }

    const createWorkflow = useAPIDialog(
        "Would you like to create a new automation?", 
        "New Automation",
        '/ptntst/createWorkflow',{}, (res,completeDialogue) => {
            setRefresh(refresh => refresh + 1)
            setDisableNameChange(true)
            setActiveWorkflow(res.data.flowId)
            const handler = setTimeout(() => {
                setDisableNameChange(false)
                setForceSwitchToFlow(v=>v + 1)
                completeDialogue();
            }, 1000);
        }, 'createWorkflow', 'finishCreateWorkflow',true)


    const deleteWorkflow = useAPIDialog(
        "Are you sure you wish to delete this workflow? After you take this action, it cannot be recovered.",
        'Delete Workflow',
        '/ptntst/deleteWorkflow',
        { flowId: activeWorkflow },
        (other,completeDialogue) => {
            setActiveWorkflow(null);
            setRefresh(refresh => refresh + 1)
            completeDialogue();
        }, 'workflow', 'deleteFlow',true)

    const experiment = () => {
        api('/ptntst/brokenButton', {}).then(res => { })
    }


    const renameWorkflow = (newName) => {
        api('/ptntst/renameWorkflow', { flowId: activeWorkflow, name: newName }).then(res => {
            setRefresh(refresh => refresh + 1)
        })
    }
    const updateWorkflow = (steps,
        stepData,
        testProfiles,
        activeTestProfileId,
        activeTestProfile,
        activeTestProfileMeta,
        workflowVersion,
        model,executionId) => {
        api('/ptntst/updateWorkflow', {
            flowId: activeWorkflow, steps,
            stepData,
            testProfiles,
            activeTestProfileId,
            activeTestProfile,
            activeTestProfileMeta,
            workflowVersion, model,executionId
        }).then(res => {
            setRefresh(refresh => refresh + 1)
        })
    }
    return (
        <WorkflowContext.Provider value={{
            manager: { 
                forceSwitchToFlow,
                activateWorkflow, 
                deleteWorkflow, 
                createWorkflow, 
                updateWorkflow, 
                renameWorkflow, 
                setActiveWorkflowName, 
                experiment },
            workflows: workflows,
            activeWorkflowName: activeWorkflowName,
            activeWorkflow: activeWorkflow
        }}>
            <DesContextProvider activeWorkflow={activeWorkflow}>
                {children}
            </DesContextProvider>
        </WorkflowContext.Provider>
    );
}

export default WorkflowContextProvider;