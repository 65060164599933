import React, { useContext, useState, useEffect } from "react";
import WorkflowContext from "../../../../../contexts/workflow/context";
import DesContext from "../../../../../contexts/singleFlow/context";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexTextField from "../../../../Lex/LexTextField";
import LexSimpleSelect from "../../../../Lex/LexSimpleSelect";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton } from "@mui/material";
import LexTypography from "../../../../Lex/LexTypography";
import ExportAllWorkspacesModal from "./ExportAllWorkspacesModal";

const Workspaces = (props) => {
    const { manager, activeWorkflowName } = useContext(WorkflowContext);
    const desCtx = useContext(DesContext);
    const desManager = desCtx.manager;
    const [exportCommand, setExportCommand] = useState("");
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [workspaceName, setWorkspaceName] = useState(desManager.activeExecutionActualName ? desManager.activeExecutionActualName : "");
    const [automationName, setAutomationName] = useState(activeWorkflowName ? activeWorkflowName : "");
    useEffect(() => {
        setWorkspaceName(desManager.activeExecutionActualName)
    }, [desManager.activeExecutionActualName])

    useEffect(() => {
        setAutomationName(activeWorkflowName)
    }, [activeWorkflowName])

    return (
        <SidebarInternalWrapper>
            <ExportAllWorkspacesModal
                open={exportModalOpen}
                onClose={() => setExportModalOpen(false)}
                onSubmit={(selected) => { 
                    desManager.exportWorkspaces(selected)
                    }}
                />
            <Box>
                <LexTypography
                    variant="h5"
                    sx={{ paddingBottom: 1 }}>
                    Workspace Settings
                </LexTypography>
            </Box>
            <Box sx={{ border: 1, borderRadius: 2, marginTop: 2, padding: 1, borderColor: 'action.disabled' }}>
                <LexTypography
                    variant="h6"
                    sx={{
                        paddingBottom: 1,
                        paddingTop: 1
                    }}>
                    Workspace Settings
                </LexTypography>
                <LexTypography
                    variant="body1"
                    sx={{
                        paddingBottom: 3
                    }}>
                    Every automation can have multiple workspaces. Each workspace stores its own set of variabe states.
                </LexTypography>
                <LexTextField
                    id="workspaceName"
                    label="Workspace Name"
                    variant="filled"
                    name="workspaceName"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={workspaceName}
                    onChange={(e) => { setWorkspaceName(e.target.value) }} />

                <LexSimpleSelect
                    key={desManager.activeExecutionName}
                    options={desManager.executionIds.map(exId => { return exId.name })}
                    handleChange={desManager.changeWorkspace}
                    value={desManager.activeExecutionName}
                    title="Select Workspace" />

                <Box style={{ width: "100%", paddingTop: "25px", display: 'flex', flexDirection: 'column' }}>
                    <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        onClick={() => { desManager.renameWorkspace(workspaceName) }}
                    >
                        Update Workspace Name
                    </Button>
                    <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        onClick={desManager.createWorkspace}
                    >
                        Add Workspace
                    </Button>
                    <Button
                        style={{ margin: '5px' }}
                        variant="contained"
                        onClick={() => { 
                            setExportModalOpen(true)
                        }}
                    >
                        Export Workspaces to CSV
                    </Button>
                </Box>
            </Box>
        </SidebarInternalWrapper>

    )
}

export default Workspaces;