import { useState, useRef, useEffect } from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import React, { useCallback } from 'react';
import { TextField, styled } from '@mui/material';
import { throttle, debounce} from 'lodash';
import ToolTip from './tooltip';

const StyledDiv = styled('div')(({ theme, rows }) => ({
    width: '100%',
    '& .DraftEditor-root': {},
    '& .valid-highlight': {},
    '& .semi-valid-highlight': {},
    '& .invalid-highlight': {
        backgroundColor: '#ffd2d2',
    },
}));

function LexHighlightTextField(props) {
    const {
        rows = 3,
        label,
        value: propValue = '', // Controlled value from parent
        onChange,
        helperText,
        variant = 'outlined',
        keysObject = {},
        possibleKeyObject={},
        style,
        ...otherProps
    } = props;

    const [localValue, setLocalValue] = useState(propValue);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [filteredKeys, setFilteredKeys] = useState([]);

    // Use a ref to store the latest onChange prop
    const onChangeRef = useRef(onChange);
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    // Throttled onChange handler stored in a ref
    const throttledOnChange = useRef(
        debounce((text) => {
            if (onChangeRef.current) {
                onChangeRef.current({
                    target: {
                        value: text,
                    },
                });
            }
        }, 800)
    ).current;

    // Handle value changes locally
    const handleLocalChange = (val) => {
        setLocalValue(val); // Update local value
        throttledOnChange(val); // Throttle update to parent
        // Detect `$` symbol and set autocomplete suggestions
        const match = val.match(/\$([a-zA-Z0-9_]*)$/);
        if (match) {
            const query = match[1];
            const keys = Object.keys(keysObject).filter((key) =>
                key.startsWith(query)
            );
            setFilteredKeys(keys);
            setShowAutocomplete(keys.length > 0);
        } else {
            setShowAutocomplete(false);
        }

    };

    // Prepare the highlight patterns with custom classes
    const highlight = [];
    const regex = /\$\w+/g;
    let match;

    while ((match = regex.exec(localValue)) !== null) {
        const key = match[0].substring(1); // Remove the '$' symbol
        highlight.push({
            highlight: match[0],
            component: ToolTip({ key, keysObject, possibleKeyObject,localValue, showAutocomplete, filteredKeys, setLocalValue, setShowAutocomplete, setFilteredKeys }),
            className: possibleKeyObject[key] ? (keysObject[key] ? 'valid-highlight':'semi-valid-highlight') : 'invalid-highlight',
        });
    }
    const editorRef = useRef(null);

    return (
        <StyledDiv rows={rows} style={style}>
            <TextField
                label={label}
                variant={variant}
                multiline
                rows={rows}
                helperText={helperText}
                InputProps={{
                    inputComponent: HighlightWithinTextarea,
                    inputProps: {
                        value: localValue,
                        onChange: handleLocalChange,
                        highlight,
                        placeholder: "",
                        ref: editorRef,
                        ...otherProps,
                    },
                    sx: { width: '100%', wordBreak: 'break-word' }
                }}
                value="" // Prevent default value handling by TextField
                sx={{ width: '100%' }}
            />
        </StyledDiv>
    );
}


export default LexHighlightTextField;