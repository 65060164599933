import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const UploadStepDataCollector = ({ configureStep, stepData, stepId,errorDisplay }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Output Variable",
                target:"varName",
                helperText:"This is the variable the output will be stored in.",
                maxLength:40,
                filter: (text)=>text.replace(/[^a-zA-Z0-9]/g, ''),
            }),
        ]}
    />
};

export default UploadStepDataCollector;