import React, { useState } from 'react';
import { MenuItem, FormControl, InputLabel, Select,FormHelperText } from '@mui/material';

const LexSimpleSelect = ({
    options,
    fullWidth=true,
    handleChange,
    variant = "outlined",
    value, style={}, 
    title,
    helperText=null,
    displayVal=(val)=>val}) => {
    return (
        <FormControl fullWidth={fullWidth} variant={variant} style={{ marginTop: '1rem',...style }}>
            <InputLabel id="model-select-label">{title}</InputLabel>
            <Select
                labelId="model-select-label"
                id="model-select"
                value={displayVal(value) || "Loading..."}
                onChange={(e,i)=>{
                    console.log(e)
                    handleChange(e)
                }}>
                {options.map((option,index) => {
                    return <MenuItem key={option+index+title+"k1"} value={option}>{option}</MenuItem>
                })}
            </Select>
            {helperText?<FormHelperText>{helperText}</FormHelperText>:null}
        </FormControl>
    );
};

export default LexSimpleSelect;