import { useState, useEffect } from "react";
import { downloadBlobURL } from "../../../utilities/downloadBlobURL";
import { set } from "lodash";

export const useWorkspaces = (
    api,
    activeWorkflow,
    activeTestProfile,
    activeTestProfileId,
    refresh) => {
    const [refreshExecutionIds, setRefreshExecutionIds] = useState(false);
    const [executionIds, setExecutionIds] = useState([]);
    const [activeExecutionName, setActiveExecutionName] = useState(null);
    const [activeExecutionActualName, setActiveExecutionActualName] = useState(null);
    const [activeExecutionId, setActiveExecutionId] = useState(null);
    const [forceNameUpdate, setForceNameUpdate] = useState(0);


    const updateExecutionName = (force = false, execIds = executionIds, execId = activeExecutionId) => {
        let executionName = execIds.find((exId) => exId.flowExecutionId === execId)
        if (executionName || force) {
            setActiveExecutionName(executionName?.name)
            setActiveExecutionActualName(executionName?.actualName)
        }
    }
    useEffect(() => {
        updateExecutionName(false, executionIds, activeExecutionId)
    }, [activeExecutionId, activeExecutionId, executionIds, forceNameUpdate])

    useEffect(() => {
        if (refreshExecutionIds) {
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                const executionIdExists = res.data.flowExecutionNames.some(exId => exId.flowExecutionId === activeExecutionId);
                if (!executionIdExists) {
                    let defaultExecution = res.data.flowExecutionNames.find((exId) => exId.flowExecutionId === activeTestProfileId);
                    setActiveExecutionId(defaultExecution?.flowExecutionId);
                } else {
                    updateExecutionName(true)
                }
                refresh()

            }).catch(e=>{console.log('error')});
            setRefreshExecutionIds(false)
        }
    }, [activeTestProfile])


    useEffect(() => {
        setRefreshExecutionIds(true)
    }, [activeWorkflow])

    const createWorkspace = () => {
        api('/ptntst/createWorkspace', { flowId: activeWorkflow }).then(res => {
            const executionId = res.data.executionId
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                setTimeout(() => {
                    setActiveExecutionId(executionId)

                }, 50)
                setTimeout(() => {
                    refresh()
                }, 100)
            });
        }).catch(e=>{console.log('error')});
    }
    const renameWorkspace = (newName) => {
        api('/ptntst/renameWorkspace', { executionId: activeExecutionId, name: newName }).then(res => {
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                setForceNameUpdate(x=>x+1)
                refresh()
            });
        }).catch(e=>{console.log('error')});
    }

    const exportWorkspaces = (exportCommand) => {
        api('/ptntst/exportWorkspaces', { flowId: activeWorkflow, exportCommand: exportCommand }, {
            responseType: 'blob',
            headers: {}
        }).then(res => {
            const href = URL.createObjectURL(res.data);
            downloadBlobURL(href, 'export.csv')
            URL.revokeObjectURL(href);
        }).catch(e=>{console.log('error')});
    }

    const changeWorkspace = (e) => {
        const workspaceName = e.target.value
        executionIds.forEach((exId) => {
            if (exId.name === workspaceName) {
                setActiveExecutionId(exId.flowExecutionId)
                refresh()
            }
        })
    }

    return { workspaceOps: { createWorkspace, exportWorkspaces, renameWorkspace, activeExecutionActualName, activeExecutionId, executionIds, changeWorkspace, activeExecutionName }, workspaceOther: { activeExecutionId: activeExecutionId } }

}