import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const GenerativeStepVerificationCollector = ({ configureStep, stepData, errorDisplay, totalPrevState,macrosObject,availableVariables,stepId, testState, ...other }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput({
                stepData:stepData,
                configureStep:configureStep,
                inputLabel:"Validation Criteria",
                target:"verification_critera",
                helperText:"This is the prompt/command that will be used to evaluate the results of multiparse.",
                multiline:true,
                rows:8,
                useHighlights:true,
                keyObject:{ ...macrosObject, ...totalPrevState },
                possibleKeyObject:availableVariables
            }),
        ]}
    />
};

export default GenerativeStepVerificationCollector;