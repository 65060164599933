import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';

const LexTextField = ({ onChange, value, filter=(text)=>text ,delay = 300, ...props }) => {
  const [inputValue, setInputValue] = useState(value);

  // Update local state if the external value prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  // Memoize the debounced onChange to prevent recreating it on every render
  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue) => {
        if (onChange) {
          onChange({ target: { value: newValue } });
        }
      }, delay),
    [onChange, delay]
  );
  // Handle input changes and trigger the debounced onChange
  const handleChange = (e) => {
    const newValuePre = e.target.value;
    const newValue = filter(newValuePre);
    setInputValue(newValue);
    debouncedOnChange(newValue);
  };

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  return <TextField {...props} onChange={handleChange} value={inputValue} />;
};

export default LexTextField;
