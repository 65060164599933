import { useEffect, useState } from 'react';
import Collector from '../../Common/Collector';
import { quickCheck, quickInput } from '../../Common/utilities';

const GenerativeStepCollector = ({
    steps,
    configureStep,
    stepData,
    errorDisplay,
    stepId,
    testState,
    totalState,
    totalPrevState,
    macrosObject,
    prevState,
    availableVariables,
    ...other }) => {

    const [prevStepConvs, setPrevStepConvs] = useState(configureStep.priorConversations())

    useEffect(() => {
        setPrevStepConvs(configureStep.priorConversations())
    }, [stepData?.useExistingContext, steps])
    let initInputFields = []

    if (stepData.useExistingContext) {
        initInputFields = [quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "Continue previous conversation",
            target: "contextToContinue",
            helperText: "You may sleect a previous conversation to continue.",
            select: true,
            options: prevStepConvs.map((conv) => "Step " + (conv[1] + 1)),
            useDisplay: true,
            displayVal: (val) => {
                const conv = prevStepConvs.find((conv) => conv[0] == val)
                console.log(conv)
                return conv ? "Step " + (conv[1] + 1) : val

            },
            customOnChange: (e) => {
                const incString = e.target.value
                const splitString = incString.split(" ")
                if(splitString.length !==2){
                    return null
                }
                const num = parseInt(incString.split(" ")[1])-1
                const conv_id = prevStepConvs.find((conv) => conv[1] == num)
                if (conv_id) {
                    return conv_id[0]
                }else{
                    return null
                }
            }
        })]
    }
    let initCheckFields = []
    if (prevStepConvs.length > 0) {
        initCheckFields=[quickCheck(stepData, configureStep, "Use Existing Context?", "useExistingContext", "Add me"),]
    }
    return <Collector
        stepId={stepId}
        inputFields={[
            ...initInputFields,
            quickInput({
                stepData: stepData,
                configureStep: configureStep,
                inputLabel: "Output Variable",
                maxLength: 40,
                filter: (text) => text.replace(/[^a-zA-Z0-9]/g, ''),
                target: "varName",
                helperText: "This is the variable the output will be stored in.",
            }),
            quickInput({
                stepData: stepData,
                configureStep: configureStep,
                inputLabel: "LLM Prompt",
                target: "prompt",
                helperText: "This is the prompt/command that will be given to the LLM.",
                multiline: true,
                rows: 8,
                useHighlights: true,
                keyObject: { ...macrosObject, ...totalPrevState },
                possibleKeyObject: availableVariables
            })
        ]}
        checkboxFields={[
            ...initCheckFields,
            quickCheck(stepData, configureStep, "Use Multiparse?", "useMultiParse", "Add me"),
            quickCheck(stepData, configureStep, "Use Citations?", "useCitations", "Add me"),
        ]}
    />
};

export default GenerativeStepCollector;