import Collector from "../../Common/Collector";
import { quickInput,quickCheck } from "../../Common/utilities";

const GenerativeSeqCollector = ({
    configureStep,
    stepData,
    errorDisplay,
    stepId,
    testState,
    totalState,
    totalPrevState,
    macrosObject,
    prevState,
    availableVariables,
    ...other }) => {
    let inputFields = [
        quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "Output Variable",
            target: "varName",
            helperText: "This is the variable the output will be stored in.",
            maxLength: 40,
            filter: (text) => text.replace(/[^a-zA-Z0-9]/g, ''),
        }),
        quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "Enumeration Label (Optional)",
            target: "enumLabel",
            helperText: "This is the label that will be applied to the enumeration.",
            maxLength: 100,
        }),
        quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "Starting Index",
            target: "startingIndex",
            helperText: "This is the starting index for the sequence.",
            filter: (text) => text.replace(/[^0-9]/g, '')
        }),
        quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "Ending Index",
            target: "endingIndex",
            helperText: "This is the ending index for the sequence.",
            filter: (text) => text.replace(/[^0-9]/g, '')
        }),
        quickInput({
            stepData: stepData,
            configureStep: configureStep,
            inputLabel: "LLM Prompt",
            target: "prompt",
            helperText: "This is the prompt/command that will be given to the LLM.",
            multiline: true,
            rows: 8,
            useHighlights: true,
            keyObject: { ...macrosObject, ...totalPrevState },
            possibleKeyObject: availableVariables
        })

    ]
    if (stepData?.useExistingContext && stepData?.sequencePrior) {
        inputFields = [
            quickInput({
                stepData: stepData,
                configureStep: configureStep,
                inputLabel: "Output Variable",
                target: "varName",
                helperText: "This is the variable the output will be stored in.",
                maxLength: 40,
                filter: (text) => text.replace(/[^a-zA-Z0-9]/g, ''),
            }),
            quickInput({
                stepData: stepData,
                configureStep: configureStep,
                inputLabel: "LLM Prompt",
                target: "prompt",
                helperText: "This is the prompt/command that will be given to the LLM.",
                multiline: true,
                rows: 8,
                useHighlights: true,
                keyObject: { ...macrosObject, ...totalPrevState },
                possibleKeyObject: availableVariables
            })
        ]
    }
    return <Collector
        stepId={stepId}
        inputFields={inputFields}
        checkboxFields={[
            quickCheck(stepData, configureStep, "Use Existing Context?", "useExistingContext", "Add me"),
        ]}
    />
};

export default GenerativeSeqCollector;
