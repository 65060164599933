import { useContext, useRef } from "react";
import DocumentViewerContext from "../../documentViewer/context";
import axios from "axios";
import DOMPurify from 'dompurify';

const getCitationKey = (citationData) => {
    // Use a unique identifier from citationData, such as an 'id' field
    return citationData.fileName || JSON.stringify(citationData);
};

const sanitizeCitationData = (data) => {
    return {
        fileName: data?.fileName || "Unknown",
        pageNum: data?.pageNum || "1",
        name: data?.name || "Unknown",
        author: data?.author || "Unknown",
    };
};

const getPageNumber = (pageNumStr) => {
    if (!pageNumStr) return 1;
    const pageNum = parseInt(pageNumStr.split('-')[0], 10);
    return isNaN(pageNum) || pageNum <= 0 ? 1 : pageNum;
};

export const useCitations = (apiMethod, flowId, executionId) => {
    const { docView } = useContext(DocumentViewerContext)
    const citationBlobCache = useRef(new Map());
    // Helper function to generate a unique key for citationData
    const showCitation = (rawCitationData) => {
        const citationData = sanitizeCitationData(rawCitationData);
        const citationKey = getCitationKey(citationData);
        const pageNum = getPageNumber(citationData.pageNum);

        if (citationBlobCache.current.has(citationKey)) {
            const url = citationBlobCache.current.get(citationKey);
            docView.loadDocument(url, pageNum, "", citationData.name, citationData.author);
        } else {
            apiMethod(
                '/ptntst/getCitation',
                {
                    flowId: flowId,
                    executionId: executionId,
                    citationData
                },
                {
                    headers: {},
                    responseType: 'arraybuffer'
                }
            )
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    citationBlobCache.current.set(citationKey, url);
                    docView.loadDocument(url, pageNum, "", citationData.name, citationData.author);
                })
                .catch(error => {
                    console.error('Error fetching citation:', error);
                });
        }
    };
    const showExternalFile = (url, fileExtension) => {
        if (citationBlobCache.current.has(url)) {
            const data = citationBlobCache.current.get(url);
            if (fileExtension === "PDF") {
                docView.loadDocument(data, 1, "", "", "");
            }
            if (fileExtension === "HTM" || fileExtension === "HTML") {
                docView.loadExternalHTMLDocument(data, 1, "", "..." + url.substring(url.length - 30, url.length), "");
            }
        } else {
            if (fileExtension === "PDF") {
                axios.get('https://corsproxy.io/' + url, { responseType: 'blob' }).then(res => {
                    const blob = new Blob([res.data], { type: 'application/pdf' });
                    const data = window.URL.createObjectURL(blob);
                    citationBlobCache.current.set(url, data);
                    docView.loadDocument(data, 1, "", "..." + url.substring(url.length - 30, url.length), "");
                }).catch(error => {
                    console.error('Error fetching external file:', error);
                });
            }
            if (fileExtension === "HTM" || fileExtension === "HTML") {
                axios.get('https://corsproxy.io/' + url).then(res => {
                    const sanitizedHTML = DOMPurify.sanitize(res.data, {
                        ALLOWED_TAGS: [
                            'p', 'b', 'i', 'u', 'em', 'strong', 'font', 'span', 'div', 'br',
                            'ul', 'ol', 'li', 'table', 'tr', 'td', 'th', 'thead', 'tbody',
                            'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
                            // 'img', // TODO: Provide support for image tags.
                        ],
                        ALLOWED_ATTR: [
                            'style', 'href', 'src', 'alt', 'title', 'align', 'width',
                            'height', 'border', 'cellpadding', 'cellspacing', 'bgcolor',
                            'font-family', 'font-size', 'color',
                        ],
                        FORBID_ATTR: ['on*'],
                        FORBID_CSS_PROP: ['behavior', 'expression', '-moz-binding'],
                        // SAFE_FOR_TEMPLATES: true,
                        ALLOWED_URI_REGEXP: /^https?:\/\//i // Allow only HTTP and HTTPS protocols
                    })
                    citationBlobCache.current.set(url, sanitizedHTML);
                    docView.loadExternalHTMLDocument(sanitizedHTML, 1, "", "..." + url.substring(url.length - 30, url.length), "", url);
                }).catch(error => {
                    console.error('Error fetching external file:', error);
                });
            }
        }

    };
    return { showCitation, showExternalFile }
}